import logo from './logo.svg';
import './App.css';
import Alllroutes from './Routes/Alllroutes';


function App() {
  return (
    <div className="App">
   <Alllroutes></Alllroutes>
    </div>
  );
}

export default App;
