export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST"
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS"
export const FETCH_DATA_FAILURE= "FETCH_DATA_FAILURE"

export const GET_SINGLE_PRODUCT_REQUEST="GET_SINGLE_PRODUCT_REQUEST"
export const GET_SINGLE_PRODUCT_SUCCESS="GET_SINGLE_PRODUCT_SUCCESS"
export const GET_SINGLE_PRODUCT_FAILURE="GET_SINGLE_PRODUCT_FAILURE"


export const ADD_PRODUCT_CART_REQUEST= "ADD_PRODUCT_CART_REQUEST"
export const ADD_PRODUCT_CART_SUCCESS= "ADD_PRODUCT_CART_SUCCESS"
export const ADD_PRODUCT_CART_FAILURE="ADD_PRODUCT_CART_FAILURE"


export const FETCH_CART_REQUEST= "FETCH_CART_REQUEST"
export const FETCH_CART_SUCCESS= "FETCH_CART_SUCCESS"
export const FETCH_CART_FAILURE="FETCH_CART_FAILURE"



export const FETCH_CHECKOUT_REQUEST= "FETCH_CHECKOUT_REQUEST"
export const FETCH_CHECKOUT_SUCCESS= "FETCH_CHECKOUT_SUCCESS"
export const FETCH_CHECKOUT_FAILURE="FETCH_CHECKOUT_FAILURE"







